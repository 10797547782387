import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import { IndexView } from "./views";
import AboutBvatGroupView from "./views/AboutBvatGroupView";
import AboutRacView from "./views/AboutRacView";
import AboutUsView from "./views/AboutUsView";
import BrainView from "./views/BrainView";
import ExpressView from "./views/ExpressView";
import NftView from "./views/NftView";
import PandoraView from "./views/PandoraView";
import PhilosophyView from "./views/PhilosophyView";
import TimesquareView from "./views/TimesquareView";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<IndexView />} />
      <Route path="/about" element={<AboutUsView />} />
      <Route path="/express" element={<ExpressView />} />
      <Route path="/brain" element={<BrainView />} />
      <Route path="/timesquare" element={<TimesquareView />} />
      <Route path="/nft" element={<NftView />} />
      <Route path="/philosophy" element={<PhilosophyView />} />
      <Route path="/pandora" element={<PandoraView />} />
      <Route path="/about-rac" element={<AboutRacView />} />
      <Route path="/about-bvat-group" element={<AboutBvatGroupView />} />
    </Routes>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
