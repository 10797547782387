/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6280b0d0a95084768ba63eed"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class BrainView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/BrainController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = BrainView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6286f10af90f57f6e2282f8c";
    htmlEl.dataset["wfSite"] = "6280b0d0a95084768ba63eed";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      BrainView.Controller !== BrainView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/bvat-9916f6.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div>
            <div
              data-w-id="c7e0fbbd-bfdd-ec38-fbbb-01e7445b9521"
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease-in"
              data-easing2="ease-out"
              role="banner"
              className="af-class-navigation-white w-nav"
            >
              <div className="af-class-container af-class-nav">
                <a href="/" className="af-class-mobile-logo w-nav-brand">
                  <img src="images/Logo-Black.png" loading="lazy" alt />
                </a>
                <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                  <a href="/" className="af-class-main-logo w-nav-brand">
                    <img
                      src="images/Logo-Black.png"
                      loading="lazy"
                      alt
                      className="af-class-image-12"
                    />
                  </a>
                  <div className="af-class-nav-links">
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/about"
                        className="af-class-header-link-block-1 w-inline-block"
                      >
                        <div className="af-class-header-link">Team</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/express"
                        className="af-class-header-link-block-2 w-inline-block"
                      >
                        <div className="af-class-header-link">express</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/philosophy"
                        className="af-class-header-link-block-3 w-inline-block"
                      >
                        <div className="af-class-header-link">philosphy</div>
                      </a>
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b9529-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/discord.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952a-445b9521"
                        alt
                        className="af-class-header-social-img"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952b-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/gitbook_logo_icon.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952c-445b9521"
                        alt
                        className="af-class-header-social-image-white"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952d-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/twitter.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952e-445b9521"
                        alt
                        className="af-class-header-social-img-white"
                      />
                    </div>
                  </div>
                </nav>
                <div className="af-class-menu-button w-nav-button">
                  <div className="w-icon-nav-menu" />
                </div>
              </div>
            </div>
            <div className="af-class-section af-class-wf-section">
              <div className="af-class-brain-contrainer af-class-content-80vh af-class-content-1">
                <img
                  src="images/Group-631748.png"
                  loading="lazy"
                  alt
                  className="af-class-image-36"
                />
                <div
                  data-w-id="990fa95b-b359-1ead-a7f4-0af9023b8dbe"
                  style={{ opacity: 0 }}
                  className="af-class-brain-content-1-box"
                >
                  <div className="af-class-text-96">$BRAIN</div>
                  <div className="af-class-text-24">
                    Welcome to the BVAT Multiverse
                  </div>
                </div>
              </div>
              <div className="af-class-brain-contrainer af-class-content-80vh af-class-content-2">
                <div className="af-class-brain-content-2">
                  <div
                    data-delay={4000}
                    data-animation="slide"
                    className="af-class-slider w-slider"
                    data-autoplay="false"
                    data-easing="ease"
                    data-hide-arrows="false"
                    data-disable-swipe="false"
                    data-autoplay-limit={0}
                    data-nav-spacing={3}
                    data-duration={500}
                    data-infinite="true"
                  >
                    <div className="w-slider-mask">
                      <div className="af-class-slide-basic w-slide">
                        <div className="af-class-slide-div">
                          <div className="af-class-text-48 af-class-brain-content-left">
                            {"{"}$BRAIN Utility{"}"}
                          </div>
                          <img
                            loading="lazy"
                            src="images/brain-content-2-center.svg"
                            alt
                            className="af-class-image-28"
                          />
                          <div className="af-class-brain-utilities-right">
                            <div className="af-class-text-block-44">Access</div>
                            <div>
                              $BRAIN holders will be able to participate in the
                              first, one of a kind hacking activity - The
                              TimesSquareHeist.
                            </div>
                            <div>
                              Token holders will be given the opportunity to be
                              featured, or have their content featured on Times
                              Square.
                            </div>
                            <div>
                              Content can range from an advertisement, an
                              expression of love, art or anything you can think
                              of.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-slide">
                        <div className="af-class-slide-div">
                          <div className="af-class-text-48 af-class-brain-content-left">
                            {"{"}$BRAIN Utility{"}"}
                          </div>
                          <img
                            src="images/brain-content-2-center.svg"
                            loading="lazy"
                            alt
                            className="af-class-image-28"
                          />
                          <div className="af-class-brain-utilities-right">
                            <div className="af-class-text-block-44">
                              Community
                            </div>
                            <div>
                              For select token holders, an exclusive invitation
                              to join our H series DAO discord community and to
                              participate in the governance of the meta
                              treasury.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-slide">
                        <div className="af-class-slide-div">
                          <div className="af-class-text-48 af-class-brain-content-left">
                            {"{"}$BRAIN Utility{"}"}
                          </div>
                          <img
                            src="images/brain-content-2-center.svg"
                            loading="lazy"
                            alt
                            className="af-class-image-28"
                          />
                          <div className="af-class-brain-utilities-right">
                            <div className="af-class-text-block-44">
                              Mint Privilege
                            </div>
                            <div>
                              The ability to mint our exclusive NFT series: the
                              “M” Series, and H3.
                            </div>
                            <div>
                              Through participating and contributing to our
                              events, you will receive $BRAIN and depending on
                              the amount of tokens held, be able to mint our
                              exclusive NFT series.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-slide">
                        <div className="af-class-slide-div">
                          <div className="af-class-text-48 af-class-brain-content-left">
                            {"{"}$BRAIN Utility{"}"}
                          </div>
                          <img
                            src="images/brain-content-2-center.svg"
                            loading="lazy"
                            alt
                            className="af-class-image-28"
                          />
                          <div className="af-class-brain-utilities-right">
                            <div className="af-class-text-block-44">
                              Exclusive Invitations
                            </div>
                            <div>
                              Token holders will be invited to attend in-person
                              gallery, and artist events, which are not limited
                              to:
                            </div>
                            <div>
                              a. Art Galleries
                              <br />
                              b. Offline Hacking Activities
                              <br />
                              c. Offline Parties and events held in
                              NYC/London/Shanghai.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-slider-arrow-left">
                      <div className="af-class-icon-2 w-icon-slider-left" />
                    </div>
                    <div className="w-slider-arrow-right">
                      <div className="af-class-icon-3 w-icon-slider-right" />
                    </div>
                    <div className="af-class-slide-nav w-slider-nav w-round" />
                  </div>
                </div>
              </div>
              <div className="af-class-brain-contrainer af-class-brain-content-3">
                <div className="af-class-brain-text-1">
                  Hacking Activity (IAO)
                </div>
              </div>
              <div className="af-class-brain-contrainer af-class-brain-content-4">
                <div
                  data-w-id="990fa95b-b359-1ead-a7f4-0af9023b8dd1"
                  style={{ opacity: 0 }}
                  className="af-class-braint-conten-6-text"
                >
                  <div className="af-class-brain-text-3">
                    TOTAL AMOUNT OF $BRAIN
                  </div>
                  <div>The total amount of $Brain is 1 Billion</div>
                  <div className="af-class-brain-text-3 af-class-brain-text-3-sub">
                    Total Supply | 100% | 1,000,000,000
                  </div>
                </div>
              </div>
              <div className="af-class-brain-contrainer af-class-braint-content-6">
                <div className="af-class-brain-content-6-info">
                  <div className="af-class-brain-chart">
                    <div className="af-class-brain-chart-up">
                      <div className="af-class-chart-content">
                        <div className="af-class-red-chart" />
                        <div className="af-class-chart-per-t">45%</div>
                        <div className="af-class-chart-subt">
                          Pandora’s Pool
                        </div>
                        <div className="af-class-chart-detail">
                          30% unlocked in the 1st month
                          <br />
                          *remaining release TBD
                        </div>
                      </div>
                      <div className="af-class-chart-content">
                        <div className="af-class-yellow-chart" />
                        <div className="af-class-chart-per-t">27%</div>
                        <div className="af-class-chart-subt">
                          Meta-X Treasury
                        </div>
                        <div className="af-class-chart-detail">
                          10% of total token will be released immediately
                          <br />
                          20% will be realeased after at the 6th month
                          <br />
                          20% will be released will at the 18th month
                          <br />
                          2.5% will be realeased monthly hereafter
                        </div>
                      </div>
                    </div>
                    <div className="af-class-line" />
                    <div className="af-class-brain-chart-bottom">
                      <div className="af-class-chart-content">
                        <div className="af-class-green-chart" />
                        <div className="af-class-chart-per-t">10%</div>
                        <div className="af-class-chart-subt">BVAT IAO</div>
                        <div className="af-class-chart-detail">
                          Initial Release
                        </div>
                      </div>
                      <div className="af-class-chart-content">
                        <div className="af-class-purple-chart" />
                        <div className="af-class-chart-per-t">8%</div>
                        <div className="af-class-chart-subt">
                          BVAT Core Members
                        </div>
                        <div className="af-class-chart-detail">
                          4.15% of token will be realeased monthly
                        </div>
                      </div>
                      <div className="af-class-chart-content">
                        <div className="af-class-blue-chart" />
                        <div className="af-class-chart-per-t">10%</div>
                        <div className="af-class-chart-subt">
                          Launch Contributors
                        </div>
                        <div className="af-class-chart-detail">
                          10% of total token will be released immediately
                          <br />
                          20% will be realeased after at the 6th month
                          <br />
                          20% will be released will at the 12th month
                          <br />
                          2.5% will be realeased monthly hereafter
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    data-w-id="21222ed2-b019-defb-c03b-9215c5ec4406"
                    data-is-ix2-target={1}
                    className="af-class-lottie-animation-2"
                    data-animation-type="lottie"
                    data-src="documents/Circle.json"
                    data-loop={0}
                    data-direction={1}
                    data-autoplay={0}
                    data-renderer="svg"
                    data-default-duration="1.3333333333333333"
                    data-duration={0}
                  />
                </div>
              </div>
              <div className="af-class-brain-contrainer af-class-brain-content-7">
                <div className="af-class-brain-content-7-left">
                  <div className="af-class-brain-text-4">
                    $Brain Distribution
                  </div>
                  <div className="af-class-brain-text-5">
                    Each time $Brain tokens are generated through IWO, they will
                    be distributed through the platform using the following
                    timeline (totaling 100%)
                  </div>
                </div>
                <img
                  src="images/brain-content-7.svg"
                  loading="lazy"
                  alt
                  className="af-class-image-27"
                />
              </div>
            </div>
            <div className="af-class-footer af-class-wf-section">
              <div className="af-class-headerwrapper af-class-footer">
                <img
                  src="images/LogoBlack.svg"
                  loading="lazy"
                  alt
                  className="af-class-image"
                />
                <div className="af-class-footer-info">
                  <div className="af-class-footer-info-left">
                    <a href="#" className="af-class-footer-info-left-link">
                      Privacy policy
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Terms of use
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Cookies preference
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      White paper
                    </a>
                  </div>
                  <div className="af-class-footer-info-right">
                    <div className="af-class-footer-info-right-t">
                      Copyright © 2022 BVAT GROUP, &nbsp;All Rights Reserved
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default BrainView;

/* eslint-enable */
