/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6280b0d0a95084768ba63eed"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class PhilosophyView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/PhilosophyController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = PhilosophyView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6281721c146ffa1ebc0fd65a";
    htmlEl.dataset["wfSite"] = "6280b0d0a95084768ba63eed";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      PhilosophyView.Controller !== PhilosophyView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/bvat-9916f6.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div>
            <div
              data-w-id="c7e0fbbd-bfdd-ec38-fbbb-01e7445b9521"
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease-in"
              data-easing2="ease-out"
              role="banner"
              className="af-class-navigation-white w-nav"
            >
              <div className="af-class-container af-class-nav">
                <a href="/" className="af-class-mobile-logo w-nav-brand">
                  <img src="images/Logo-Black.png" loading="lazy" alt />
                </a>
                <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                  <a href="/" className="af-class-main-logo w-nav-brand">
                    <img
                      src="images/Logo-Black.png"
                      loading="lazy"
                      alt
                      className="af-class-image-12"
                    />
                  </a>
                  <div className="af-class-nav-links">
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/about"
                        className="af-class-header-link-block-1 w-inline-block"
                      >
                        <div className="af-class-header-link">Team</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/express"
                        className="af-class-header-link-block-2 w-inline-block"
                      >
                        <div className="af-class-header-link">express</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/philosophy"
                        aria-current="page"
                        className="af-class-header-link-block-3 w-inline-block w--current"
                      >
                        <div className="af-class-header-link">philosphy</div>
                      </a>
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b9529-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/discord.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952a-445b9521"
                        alt
                        className="af-class-header-social-img"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952b-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/gitbook_logo_icon.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952c-445b9521"
                        alt
                        className="af-class-header-social-image-white"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952d-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/twitter.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952e-445b9521"
                        alt
                        className="af-class-header-social-img-white"
                      />
                    </div>
                  </div>
                </nav>
                <div className="af-class-menu-button w-nav-button">
                  <div className="w-icon-nav-menu" />
                </div>
              </div>
            </div>
            <div className="af-class-wf-section">
              <div className="af-class-philosophy-banner">
                <div
                  data-w-id="097c5965-8e40-95a2-c59a-f525b418ba97"
                  style={{ opacity: 0 }}
                  className="af-class-about-us-banner"
                >
                  <h1 className="af-class-phi-banner-heading">Philosophy</h1>
                  <div className="af-class-phi-banner-title">
                    <img
                      src="images/Phi-Vector.svg"
                      loading="lazy"
                      alt
                      className="af-class-image-11"
                    />
                  </div>
                  <div className="af-class-pho-banner-t">
                    BVAT AKA. "BRAIN IN A VAT"
                  </div>
                </div>
              </div>
              <div className="af-class-phi-content-1">
                <div
                  data-w-id="bca861ae-1268-27ec-f67c-b6c8e9c0802f"
                  style={{ opacity: 0 }}
                  className="af-class-phi-content-1-text"
                >
                  <div className="af-class-phi-big-text">90%</div>
                  <div className="af-class-pho-banner-t">
                    of information transmitted to the brain is visual
                  </div>
                </div>
              </div>
              <div className="af-class-phi-content-2">
                <div
                  data-w-id="88faf7cd-c3c0-32a4-337f-c8ca4a6eff79"
                  style={{ opacity: 0 }}
                  className="af-class-phi-content-2-text"
                >
                  <div className="af-class-pho-banner-t">
                    The human brain processes visual content
                  </div>
                  <div className="af-class-phi-content-2-sub">
                    <div className="af-class-phi-big-text af-class-phi-phone-center">
                      6,000 x faster
                    </div>
                    <div className="af-class-pho-banner-t">than text</div>
                  </div>
                </div>
              </div>
              <div className="af-class-phi-content-3">
                <div
                  data-w-id="89f418e0-c868-39ad-ca4b-77d03df56de1"
                  style={{ opacity: 0 }}
                  className="af-class-phi-content-3-text"
                >
                  <div className="af-class-phi-middle-text">
                    Is What you see really what you get?
                  </div>
                </div>
              </div>
              <div className="af-class-phi-content-4">
                <div className="af-class-phi-content-4-half">
                  <img
                    className="af-class-image-19"
                    src="images/4df8bfa70da703c01334bd6bdb44cbcb.png"
                    width={427}
                    alt
                    style={{ opacity: 0 }}
                    sizes="(max-width: 767px) 80vw, 50vw"
                    data-w-id="40b782d8-bcb4-c870-2d2a-395337296c38"
                    loading="lazy"
                    srcSet="images/4df8bfa70da703c01334bd6bdb44cbcb-p-500.png 500w, images/4df8bfa70da703c01334bd6bdb44cbcb-p-800.png 800w, images/4df8bfa70da703c01334bd6bdb44cbcb.png 817w"
                  />
                </div>
                <div
                  data-w-id="ceb5f2bf-3326-f287-3a93-f07abc8730e2"
                  style={{ opacity: 0 }}
                  className="af-class-phi-content-4-half af-class-phi-content-4-right"
                >
                  <div className="af-class-phi-big-text">René Descartes</div>
                  <div className="af-class-phi-xsmall-text">
                    The Brain in a Vat is a thought experiment demonstrating
                    Descarte’s evil demon, who presents a complete illusion of
                    an external world to deceive us. Bearing the resemblance of
                    this thought experiment, the BVAT Group thrives for
                    undertaking the habitual philosophical quest for
                    authenticity. Our adventure into exploring the axiom of the
                    world has just begun: articulating integrity, utility and
                    community in the crypto and NFT space.
                  </div>
                </div>
                <img
                  src="images/Phi-content-4.svg"
                  loading="lazy"
                  alt
                  className="af-class-overload-lottie"
                />
              </div>
              <div className="af-class-phi-content-5">
                <img
                  src="images/Phi-content-5.png"
                  loading="lazy"
                  style={{ opacity: 0 }}
                  data-w-id="1245f34b-310c-6433-5d3b-8ee3df4b21dc"
                  srcSet="images/Phi-content-5-p-500.png 500w, images/Phi-content-5.png 761w"
                  sizes="(max-width: 479px) 70vw, (max-width: 767px) 74vw, (max-width: 991px) 38vw, 40vw"
                  alt
                  className="af-class-image-18"
                />
                <div
                  data-w-id="17bb1a9f-ca5b-e07e-23b8-5ccbb329ebb7"
                  style={{ opacity: 0 }}
                  className="af-class-phi-content-5-sub"
                >
                  <div className="af-class-phi-middle-text af-class-black-text">
                    The "Quest"
                  </div>
                  <div className="af-class-phi-xsmall-text">
                    The original aspiration of BIAV is to construct the Root Lab
                    Art Community and X Metauniverse full of epoch-making
                    artworks, based on the combination of Interactive Art,
                    Performance Art, Raw Art and Traditional Art in the Web 3.0
                    generation. We integrate digital value into reality, while
                    conveying the realistic philosophy to the digital world.
                  </div>
                </div>
              </div>
              <div className="af-class-phi-content-6" />
            </div>
            <div className="af-class-footer af-class-wf-section">
              <div className="af-class-headerwrapper af-class-footer">
                <img
                  src="images/LogoBlack.svg"
                  loading="lazy"
                  alt
                  className="af-class-image"
                />
                <div className="af-class-footer-info">
                  <div className="af-class-footer-info-left">
                    <a href="#" className="af-class-footer-info-left-link">
                      Privacy policy
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Terms of use
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Cookies preference
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      White paper
                    </a>
                  </div>
                  <div className="af-class-footer-info-right">
                    <div className="af-class-footer-info-right-t">
                      Copyright © 2022 BVAT GROUP, &nbsp;All Rights Reserved
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default PhilosophyView;

/* eslint-enable */
