/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6280b0d0a95084768ba63eed"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class AboutRacView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/AboutRacController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = AboutRacView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "628335a1e5cc5906b3f727c9";
    htmlEl.dataset["wfSite"] = "6280b0d0a95084768ba63eed";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      AboutRacView.Controller !== AboutRacView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/bvat-9916f6.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div className="af-class-body">
            <div
              data-w-id="c7e0fbbd-bfdd-ec38-fbbb-01e7445b9521"
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease-in"
              data-easing2="ease-out"
              role="banner"
              className="af-class-navigation-white w-nav"
            >
              <div className="af-class-container af-class-nav">
                <a href="/" className="af-class-mobile-logo w-nav-brand">
                  <img src="images/Logo-Black.png" loading="lazy" alt />
                </a>
                <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                  <a href="/" className="af-class-main-logo w-nav-brand">
                    <img
                      src="images/Logo-Black.png"
                      loading="lazy"
                      alt
                      className="af-class-image-12"
                    />
                  </a>
                  <div className="af-class-nav-links">
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/about"
                        className="af-class-header-link-block-1 w-inline-block"
                      >
                        <div className="af-class-header-link">Team</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/express"
                        className="af-class-header-link-block-2 w-inline-block"
                      >
                        <div className="af-class-header-link">express</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/philosophy"
                        className="af-class-header-link-block-3 w-inline-block"
                      >
                        <div className="af-class-header-link">philosphy</div>
                      </a>
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b9529-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/discord.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952a-445b9521"
                        alt
                        className="af-class-header-social-img"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952b-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/gitbook_logo_icon.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952c-445b9521"
                        alt
                        className="af-class-header-social-image-white"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952d-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/twitter.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952e-445b9521"
                        alt
                        className="af-class-header-social-img-white"
                      />
                    </div>
                  </div>
                </nav>
                <div className="af-class-menu-button w-nav-button">
                  <div className="w-icon-nav-menu" />
                </div>
              </div>
            </div>
            <div className="af-class-headerwrapper2">
              <div
                data-w-id="b0841713-9f18-0ae0-5bfc-1eec7c59bcb3"
                style={{ opacity: 0 }}
                className="af-class-header2"
              >
                <img
                  src="images/WechatIMG754.jpeg"
                  loading="lazy"
                  sizes="(max-width: 767px) 54vw, (max-width: 991px) 19vw, 35vw"
                  srcSet="images/WechatIMG754-p-500.jpeg 500w, images/WechatIMG754-p-800.jpeg 800w, images/WechatIMG754-p-1080.jpeg 1080w, images/WechatIMG754-p-1600.jpeg 1600w, images/WechatIMG754-p-2000.jpeg 2000w, images/WechatIMG754.jpeg 2233w"
                  alt
                  className="af-class-image-30"
                />
                <div className="af-class-rac-info-banner-1">
                  <h1 className="af-class-rac-info-banner-1-header">RAC</h1>
                  <div className="af-class-rac-info-banner-1-t">
                    RAC (Root Lab Art Collective) is a community-based art and
                    culture forum focusing on accommodating
                    <br />
                    art-adjacent collaborative projects with the BVAT Group.
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-div-block-6">
              <div
                data-w-id="4e549b45-b7ba-621f-4f33-65ee160c0491"
                style={{ opacity: 0 }}
                className="af-class-div-block-7"
              >
                <div className="af-class-rac-info-banner-1 af-class-boader-banner-1">
                  <h1 className="af-class-rac-info-banner-1-header af-class-rac-info-banner-1-header-mini">
                    a web 3.0 network
                    <br />
                    for the art world
                  </h1>
                  <div className="af-class-rac-info-banner-1-t">
                    Root Art collective is an interdisciplinary platform, for
                    new kinds of art-adjacentcollaboration; a cultural art
                    technology ecological company born in New York.
                    <br />
                    <br />
                    RAC works to bring NFT art investing into a brand
                    newdialogue through our platform. We are prospecting a
                    future where there’s an equal opportunity for all creators
                    worldwide.
                  </div>
                </div>
                <img
                  src="images/WechatIMG754.jpeg"
                  loading="lazy"
                  sizes="(max-width: 767px) 54vw, (max-width: 991px) 12vw, 35vw"
                  srcSet="images/WechatIMG754-p-500.jpeg 500w, images/WechatIMG754-p-800.jpeg 800w, images/WechatIMG754-p-1080.jpeg 1080w, images/WechatIMG754-p-1600.jpeg 1600w, images/WechatIMG754-p-2000.jpeg 2000w, images/WechatIMG754.jpeg 2233w"
                  alt
                  className="af-class-image-30"
                />
              </div>
            </div>
            <div className="af-class-div-block-8">
              <div
                data-w-id="6f9cf53b-a5a8-a8ff-a618-43c665ec2289"
                style={{ opacity: 0 }}
              >
                <div className="af-class-text-block-6">
                  a fully artist-oriented, on-chain <br />
                  collective art movement
                </div>
              </div>
            </div>
            <div className="af-class-div-block-9">
              <div
                data-w-id="f90872d4-9c12-5230-b54a-fa09e053649e"
                style={{ opacity: 0 }}
                className="af-class-div-block-10"
              >
                <h1 className="af-class-heading-5">RAC Ecosystem</h1>
                <div className="af-class-text-block-7">
                  The RAC Ecosystem consists of Artist Incubation and Art
                  Collection Pipeline. The Incubation will take place in the
                  BVAT Metaverse infrastructure, in the form of Artists in
                  Residence and Meta Panel Discussion.
                  <br />
                  The Art Collection Pipeline will focus on the IP
                  authentication and realization for the artists; and
                  Crypto-Investment Equity for the collectors and investors.
                </div>
                <a href="#" className="af-class-button w-button">
                  Discover
                </a>
              </div>
            </div>
            <div className="af-class-div-block-11">
              <div
                data-w-id="f1d3c051-b14a-c76e-a33b-d6e08936a513"
                style={{ opacity: 0 }}
                className="af-class-div-block-12"
              >
                <div className="af-class-text-block-8">incubation</div>
              </div>
              <div
                data-w-id="1947170d-74f7-0aa1-6a23-cd083525a60c"
                style={{ opacity: 0 }}
                className="af-class-div-block-12"
              >
                <div className="af-class-text-block-8">pipeline</div>
              </div>
              <div
                data-w-id="761f8259-9129-51f1-d78a-49f1c6c065fb"
                style={{ opacity: 0 }}
                className="af-class-div-block-12"
              >
                <div className="af-class-text-block-8">engagement</div>
              </div>
            </div>
            <div className="af-class-black-banner-1 af-class-black-banner-bg-1">
              <div
                data-w-id="f6410c4c-09ba-7e86-1a90-5a625cc2fbdc"
                style={{ opacity: 0 }}
                className="af-class-black-banner-box"
              >
                <h1 className="af-class-heading-6 af-class-rac-mirror">
                  Incubation
                </h1>
                <div className="af-class-text-block-9 af-class-rac-mirror-t">
                  Through the BVAT Metaverse infrastructure, Root Lab Art will
                  announce Web-3-AIR and invite prospected artists to claim
                  their spots in our vitural residency space. All connected
                  artists will be invited to RAC’s future NFT forging plans,
                  such as “M” Series with BVAT.
                </div>
              </div>
            </div>
            <div className="af-class-black-banner-1 af-class-black-banner-bg-2">
              <div
                data-w-id="11b0789f-d450-00d9-5612-6066c6950718"
                style={{ opacity: 0 }}
                className="af-class-black-banner-box"
              >
                <h1 className="af-class-heading-6">pipeline</h1>
                <div className="af-class-text-block-9">
                  RAC provides a cutting-edge venue for art collectors,
                  <br />
                  practitioners and related organizations: an on-chain pipeline
                  for the trade,
                  <br />
                  research, and management of crypto art collection.
                  <br />
                  They covered new art forms based on large-scale online and
                  offline
                  <br />
                  interactive art. Formed with BVAT Group for comprehensive
                  web3.0 art
                  <br />
                  platform at its core.
                </div>
              </div>
            </div>
            <div className="af-class-black-banner-1 af-class-black-banner-bg-3">
              <div
                data-w-id="bbbb78e3-3f69-ec77-b230-2b347164044c"
                style={{ opacity: 0 }}
                className="af-class-black-banner-box"
              >
                <h1 className="af-class-heading-6 af-class-rac-mirror">
                  Engagement
                </h1>
                <div className="af-class-text-block-9 af-class-rac-mirror-t">
                  We will facilitate various Panel Discussions through the BVAT
                  network. The RAC is responsible for hosting
                  Online-Offline-Transcending art events dedicated to both of
                  our communities.
                </div>
              </div>
            </div>
            <div className="af-class-black-banner-1 af-class-black-last">
              <div
                data-w-id="8e5a59ef-e769-cfec-e3fe-6e52863d92d9"
                style={{ opacity: 0 }}
                className="af-class-black-banner-box"
              >
                <h1 className="af-class-heading-6">Prospectives</h1>
                <div className="af-class-text-block-9">
                  In contemporary times, art not only affects the public in the
                  form of works of art, but actually enters our life, and the
                  NFT formed by the combination of art and investment does
                  exactly this. <br />
                  <br />
                  People who care about finance and investing are the shortcuts
                  to access and understand art in their own way. Promote the
                  blending of the two parties, so that the public does not need
                  to deliberately enter the gallery or the exhibition hall when
                  browsing art, but is
                  <br />
                  <br />
                  Sitting at home, in front of the computer, everyone
                  understands and judges the value of a work of art with the
                  attitude of an investor.
                  <br />
                  <br />
                  This kind of blending will inevitably relieve people from
                  tense and cumbersome investments, obtain rest from art, and
                  get an unknown escape from life.
                </div>
              </div>
            </div>
            <div className="af-class-div-block-13" />
            <div className="af-class-footer af-class-wf-section">
              <div className="af-class-headerwrapper af-class-footer">
                <img
                  src="images/LogoBlack.svg"
                  loading="lazy"
                  alt
                  className="af-class-image"
                />
                <div className="af-class-footer-info">
                  <div className="af-class-footer-info-left">
                    <a href="#" className="af-class-footer-info-left-link">
                      Privacy policy
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Terms of use
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Cookies preference
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      White paper
                    </a>
                  </div>
                  <div className="af-class-footer-info-right">
                    <div className="af-class-footer-info-right-t">
                      Copyright © 2022 BVAT GROUP, &nbsp;All Rights Reserved
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default AboutRacView;

/* eslint-enable */
