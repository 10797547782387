/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6280b0d0a95084768ba63eed"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class TimesquareView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/TimesquareController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = TimesquareView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "62858070bc671dcd88ab89bd";
    htmlEl.dataset["wfSite"] = "6280b0d0a95084768ba63eed";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      TimesquareView.Controller !== TimesquareView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/bvat-9916f6.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div className="af-class-body-2">
            <div
              data-w-id="c7e0fbbd-bfdd-ec38-fbbb-01e7445b9521"
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease-in"
              data-easing2="ease-out"
              role="banner"
              className="af-class-navigation-white w-nav"
            >
              <div className="af-class-container af-class-nav">
                <a href="/" className="af-class-mobile-logo w-nav-brand">
                  <img src="images/Logo-Black.png" loading="lazy" alt />
                </a>
                <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                  <a href="/" className="af-class-main-logo w-nav-brand">
                    <img
                      src="images/Logo-Black.png"
                      loading="lazy"
                      alt
                      className="af-class-image-12"
                    />
                  </a>
                  <div className="af-class-nav-links">
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/about"
                        className="af-class-header-link-block-1 w-inline-block"
                      >
                        <div className="af-class-header-link">Team</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/express"
                        className="af-class-header-link-block-2 w-inline-block"
                      >
                        <div className="af-class-header-link">express</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/philosophy"
                        className="af-class-header-link-block-3 w-inline-block"
                      >
                        <div className="af-class-header-link">philosphy</div>
                      </a>
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b9529-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/discord.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952a-445b9521"
                        alt
                        className="af-class-header-social-img"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952b-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/gitbook_logo_icon.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952c-445b9521"
                        alt
                        className="af-class-header-social-image-white"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952d-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/twitter.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952e-445b9521"
                        alt
                        className="af-class-header-social-img-white"
                      />
                    </div>
                  </div>
                </nav>
                <div className="af-class-menu-button w-nav-button">
                  <div className="w-icon-nav-menu" />
                </div>
              </div>
            </div>
            <div className="af-class-div-block-26">
              <div
                data-w-id="6dc40b19-65b8-3ad4-2291-72933fc641a5"
                style={{ opacity: 0 }}
                className="af-class-div-block-27"
              >
                <div className="af-class-text-block-19">
                  The #TimesSquareHeist
                </div>
                <div className="af-class-text-block-20">
                  The #TimesSquareHeist is a large-scale collaborative
                  art-creation event facilitated by the BVAT group. Each
                  successful IAO Applicant will be invited to take part in the
                  creation of this public NFT art Performance. BVAT will curate
                  the statements of all initial contributors into a
                  cyber-reality-transitive visual art, and showcase it to the
                  world on the LED Screens in Times Square
                </div>
              </div>
            </div>
            <div className="af-class-div-block-28">
              <div
                data-w-id="8fc22224-e9b7-537e-0b6b-82833d4d05bd"
                style={{ opacity: 0 }}
                className="af-class-div-block-29"
              >
                <div className="af-class-text-block-21">
                  The Times Square is your stage
                </div>
                <div className="af-class-text-block-22">
                  The #TimesSquareHeist symbolizes an act of liberty and
                  resembles our metaphorical appeal for social mobility. BVAT
                  thrives to undertake the heist as a gesture of social and
                  cultural mobilization, to collaboratively override the
                  signifiers of the imperishable precedent of the encapsulated
                  capitalist culture.
                </div>
              </div>
            </div>
            <div className="af-class-div-block-30">
              <div className="af-class-ts-content-3-sub">
                <img
                  src="images/Frame-631838.png"
                  loading="lazy"
                  sizes="(max-width: 767px) 90vw, 40vw"
                  srcSet="images/Frame-631838-p-500.png 500w, images/Frame-631838.png 760w"
                  alt
                  className="af-class-image-33"
                />
                <div
                  data-w-id="8f44e6c9-5429-7031-8a20-11c5c9d794dc"
                  style={{ opacity: 0 }}
                  className="af-class-div-block-33"
                >
                  <div className="af-class-text-block-23">
                    Heist as real-life desire
                  </div>
                  <div className="af-class-text-block-23-copy">
                    BVAT aims to facilitate an artist-oriented, on-chain
                    collective art movement in response to the advent of Web 3,
                    by launching the #TimesSquareHeist. The Heist refers to the
                    highly favored heist genre film, its Robin-Hood-style
                    storytelling entails the most direct contemplation of social
                    ideology. Flouting censorship, and challenging the existing
                    infrastructure all actions of which entailed by the heist
                    movie not only lend the audiences to quality entertainment
                    but also reveal the anti-capitalist quest of contemporary
                    society. Witnessing a group of burglars committing a
                    complicated theft through coordination and collaboration
                    triggers the very kinaesthetic empathy of the audience.
                  </div>
                </div>
              </div>
              <div className="af-class-ts-content-3-sub">
                <img
                  src="images/Frame-631838-1.png"
                  loading="lazy"
                  sizes="(max-width: 767px) 90vw, 40vw"
                  srcSet="images/Frame-631838-1-p-500.png 500w, images/Frame-631838-1.png 760w"
                  alt
                  className="af-class-image-34 af-class-image-mirror"
                />
                <div
                  data-w-id="2fa960f6-5398-693c-5d00-a24be78fff4b"
                  style={{ opacity: 0 }}
                  className="af-class-div-block-33 af-class-text-mirror"
                >
                  <div className="af-class-text-block-23">The web3.0 Heist</div>
                  <div className="af-class-text-block-23-copy">
                    The economic development has always been closely tied to the
                    metamorphosis of art and cultural movements. We currently
                    rely on centralized stream media and technology institutions
                    to exchange information under the institutional censorship.
                    Web 3 will be an avenue to decentralized communication while
                    maintaining the integrity of the individual and collective
                    agency, free from unjustified infringement on individual
                    right to publish and access information.
                  </div>
                </div>
              </div>
              <div className="af-class-ts-content-3-sub">
                <img
                  src="images/Frame-631839.png"
                  loading="lazy"
                  sizes="(max-width: 767px) 90vw, 40vw"
                  srcSet="images/Frame-631839-p-500.png 500w, images/Frame-631839.png 760w"
                  alt
                  className="af-class-image-33"
                />
                <div
                  data-w-id="bbbddde5-0b25-96c6-2d36-54b08e53d8b1"
                  style={{ opacity: 0 }}
                  className="af-class-div-block-33"
                >
                  <div className="af-class-text-block-23">Execution</div>
                  <div className="af-class-text-block-23-copy-copy">
                    How do we respond to the changing times? Where do we stand?
                  </div>
                  <div className="af-class-text-block-24">
                    The heist will make the digital billboards in Times Square a
                    stage for all BVAT IAO contributors, by collectively
                    declaring visual occupancy for a long duration at one of the
                    highest-traffic locations in the world. The heist manifests
                    our desire to resist boundaries, rebel against artificially
                    imposed limits, and extend options.
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-div-block-35">
              <div className="af-class-div-block-34">
                <div className="af-class-text-block-25">
                  Activity Step 1: Virtual Hacking
                </div>
                <div className="af-class-div-block-36">
                  <div className="af-class-text-block-27">1.</div>
                  <div className="af-class-text-block-26">
                    BVAT will rent 60 screens in Times Square, and develop a 3D
                    modeling website for the real scene of Times Square, so that
                    participants can get the hacking experience of being in
                    Times Square virtually.
                  </div>
                </div>
                <div className="af-class-div-block-36">
                  <div className="af-class-text-block-27">2.</div>
                  <div className="af-class-text-block-26">
                    Members are encouraged to participate in fundraising on
                    hackingtimessquare.com to get Brain tokens and leave
                    comments. You could say whatever you want; An advertisement,
                    a company logo, a DAO organization, an NFT, an interesting
                    story, a word you want to convey to the whole world, a
                    spirit, a declaration of war, a notice of racial equality,
                    concern for a certain group, a certain Tokens or a certain
                    NFT project, the next 100x, 1000x investment opportunity,
                    you love someone, or complain about the world...
                  </div>
                </div>
                <div className="af-class-div-block-36">
                  <div className="af-class-text-block-27">3.</div>
                  <div className="af-class-text-block-26">
                    The message of each fundraiser will be encrypted and cipher
                    text will be generated and displayed on the screen of Times
                    Square.
                  </div>
                </div>
                <div className="af-class-div-block-36">
                  <div className="af-class-text-block-27">4.</div>
                  <div className="af-class-text-block-26">
                    Participants can participate in the decryption of the
                    ciphertext on our official website： and each participant
                    has the freedom to choose whether to disclose their own
                    message ciphertext, which is to disclose the decrypted
                    plaintext of their own ciphertext.
                  </div>
                </div>
                <div className="af-class-div-block-36">
                  <div className="af-class-text-block-27">5.</div>
                  <div className="af-class-text-block-26">
                    Each user's ciphertext or plaintext will generate an
                    exclusive NFT, and participants can take pictures of the NFT
                    generated by their own messages at a discounted price as a
                    souvenir
                  </div>
                </div>
              </div>
              <div className="af-class-div-block-34">
                <div className="af-class-text-block-25">
                  Step 2: Real Scene Hacking
                </div>
                <div className="af-class-div-block-36">
                  <div className="af-class-text-block-27">1.</div>
                  <div className="af-class-text-block-26">
                    We will jointly develop human-machine special effects filter
                    props blind box with Root Art Collective. It includes 4
                    Hacker special effects, and two limited edition hidden
                    models.
                  </div>
                </div>
                <div className="af-class-div-block-36">
                  <div className="af-class-text-block-27">2.</div>
                  <div className="af-class-text-block-26">
                    Participants scan the code on the screen of Times Square to
                    obtain permission to use BVAT HACKER special effects.
                  </div>
                </div>
                <div className="af-class-div-block-36">
                  <div className="af-class-text-block-27">3.</div>
                  <div className="af-class-text-block-26">
                    Participants can use human-machine special effects to take
                    photos with the Times Square Heist event. Photos or videos
                    can be forwarded to social media through the automatically
                    generated links.
                  </div>
                </div>
                <div className="af-class-div-block-36">
                  <div className="af-class-text-block-27">4.</div>
                  <div className="af-class-text-block-26">
                    After forwarding, you can download photos/short videos with
                    special effects as a good memory of participating in Hacking
                    on the spot.
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-div-block-37" />
            <div className="af-class-footer af-class-wf-section">
              <div className="af-class-headerwrapper af-class-footer">
                <img
                  src="images/LogoBlack.svg"
                  loading="lazy"
                  alt
                  className="af-class-image"
                />
                <div className="af-class-footer-info">
                  <div className="af-class-footer-info-left">
                    <a href="#" className="af-class-footer-info-left-link">
                      Privacy policy
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Terms of use
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Cookies preference
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      White paper
                    </a>
                  </div>
                  <div className="af-class-footer-info-right">
                    <div className="af-class-footer-info-right-t">
                      Copyright © 2022 BVAT GROUP, &nbsp;All Rights Reserved
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default TimesquareView;

/* eslint-enable */
