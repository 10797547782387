/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6280b0d0a95084768ba63eed"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class IndexView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/IndexController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = IndexView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "628294618d226384153ab888";
    htmlEl.dataset["wfSite"] = "6280b0d0a95084768ba63eed";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      IndexView.Controller !== IndexView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/bvat-9916f6.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div className="af-class-home-center">
            <div className="af-class-container af-class-home-wrapper">
              <div className="af-class-home-left">
                <div className="af-class-home-logo">
                  <a
                    href="/"
                    aria-current="page"
                    className="w-inline-block w--current"
                  >
                    <img
                      src="images/LogoBlack.svg"
                      loading="lazy"
                      alt
                      className="af-class-image-40"
                    />
                  </a>
                  <div className="af-class-home-social-media">
                    <div className="af-class-header-logo-div">
                      <img src="images/discord.svg" loading="lazy" alt />
                    </div>
                    <div className="af-class-header-logo-div">
                      <img src="images/twitter.svg" loading="lazy" alt />
                    </div>
                    <div className="af-class-header-logo-div">
                      <img
                        src="images/gitbook_logo_icon.svg"
                        loading="lazy"
                        alt
                      />
                    </div>
                  </div>
                </div>
                <a
                  href="/pandora"
                  className="af-class-home-square af-class-home-border w-inline-block"
                >
                  <div className="af-class-div-block-64">
                    <img
                      src="images/1722412076.png"
                      loading="lazy"
                      sizes="(max-width: 479px) 270px, (max-width: 767px) 410px, (max-width: 991px) 123px, 8vw"
                      srcSet="images/1722412076-p-500.png 500w, images/1722412076.png 600w"
                      alt
                      className="af-class-image-15"
                    />
                  </div>
                </a>
              </div>
              <div className="af-class-home-right">
                <a
                  href="/express"
                  className="af-class-home-express w-inline-block"
                >
                  <div className="af-class-div-block-62">
                    <img
                      src="images/express.gif"
                      loading="lazy"
                      alt
                      className="af-class-image-14"
                    />
                    <div className="af-class-div-block-61">
                      <p className="af-class-paragraph-3">bvat express</p>
                      <p className="af-class-paragraph-4">collective</p>
                    </div>
                  </div>
                </a>
                <div className="af-class-home-info">
                  <div className="af-class-home-about">
                    <a
                      id="about-us"
                      href="/about"
                      className="af-class-home-about-us w-inline-block"
                    >
                      <img src="images/Frame.png" loading="lazy" alt />
                      <p className="af-class-bottom-align-paragrah">about US</p>
                    </a>
                  </div>
                  <div className="af-class-home-philosophy">
                    <a
                      href="/philosophy"
                      className="af-class-link-block-2 w-inline-block"
                    >
                      <img
                        src="images/Group.svg"
                        loading="lazy"
                        alt
                        className="af-class-image-16"
                      />
                      <p className="af-class-bottom-align-paragrah">
                        philosophy
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default IndexView;

/* eslint-enable */
