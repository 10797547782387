/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6280b0d0a95084768ba63eed"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class ExpressView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/ExpressController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = ExpressView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6284612d04f98c134596e9c8";
    htmlEl.dataset["wfSite"] = "6280b0d0a95084768ba63eed";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      ExpressView.Controller !== ExpressView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/bvat-9916f6.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div className="af-class-body-4">
            <div
              data-w-id="a3e9181a-8174-bbcc-2967-82a3a88e7261"
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease"
              data-easing2="ease"
              role="banner"
              className="af-class-navbar-3 w-nav"
            >
              <div className="af-class-container af-class-nav">
                <a href="/" className="af-class-mobile-logo w-nav-brand">
                  <img src="images/Frame-114.png" loading="lazy" alt />
                </a>
                <nav
                  role="navigation"
                  className="af-class-nav-menu af-class-nav-menu-white w-nav-menu"
                >
                  <a href="/" className="af-class-main-logo w-nav-brand">
                    <img
                      src="images/Frame-114.png"
                      loading="lazy"
                      alt
                      className="af-class-image-12"
                    />
                  </a>
                  <div className="af-class-nav-links">
                    <div className="af-class-header-logo-div af-class-header-tab af-class-header-tab-white">
                      <a
                        href="/about"
                        className="af-class-header-link-block-1 w-inline-block"
                      >
                        <div className="af-class-header-link-white">Team</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab af-class-header-tab-white">
                      <a
                        href="/express"
                        aria-current="page"
                        className="af-class-header-link-block-2 w-inline-block w--current"
                      >
                        <div className="af-class-header-link-white">
                          express
                        </div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab af-class-header-tab-white">
                      <a
                        href="/philosophy"
                        className="af-class-header-link-block-3 w-inline-block"
                      >
                        <div className="af-class-header-link-white">
                          Philosiphy
                        </div>
                      </a>
                    </div>
                    <div
                      id="w-node-_61dee67e-4569-61bc-c88f-e62399eeaa22-4596e9c8"
                      className="af-class-header-logo-div-white-1"
                    >
                      <img
                        src="images/vector_2.png"
                        loading="lazy"
                        id="w-node-_61dee67e-4569-61bc-c88f-e62399eeaa23-4596e9c8"
                        alt
                        className="af-class-header-social-img"
                      />
                    </div>
                    <div
                      id="w-node-_61dee67e-4569-61bc-c88f-e62399eeaa24-4596e9c8"
                      className="af-class-header-logo-div-white-1"
                    >
                      <img
                        src="images/gitbook_logo_icon_168173-1.png"
                        loading="lazy"
                        id="w-node-_61dee67e-4569-61bc-c88f-e62399eeaa25-4596e9c8"
                        alt
                        className="af-class-header-social-image-white"
                      />
                    </div>
                    <div
                      id="w-node-_61dee67e-4569-61bc-c88f-e62399eeaa26-4596e9c8"
                      className="af-class-header-logo-div-white-1"
                    >
                      <img
                        src="images/vector_1.png"
                        loading="lazy"
                        id="w-node-_61dee67e-4569-61bc-c88f-e62399eeaa27-4596e9c8"
                        alt
                        className="af-class-header-social-img-white"
                      />
                    </div>
                  </div>
                </nav>
                <div className="af-class-menu-button af-class-menu-white w-nav-button">
                  <div className="af-class-nav-white w-icon-nav-menu" />
                </div>
              </div>
            </div>
            <div className="af-class-express-banner">
              <div
                data-w-id="502ae720-a2e3-aee4-0b84-7ebf3ce0c301"
                data-is-ix2-target={1}
                className="af-class-lottie-animation"
                data-animation-type="lottie"
                data-src="documents/Comp-new.mp4.lottie.json"
                data-loop={0}
                data-direction={1}
                data-autoplay={0}
                data-renderer="svg"
                data-default-duration="5.454545454545454"
                data-duration={0}
              />
            </div>
            <div className="af-class-express-content">
              <div className="af-class-express-arrow1">
                <img
                  src="images/map-route.png"
                  loading="lazy"
                  alt
                  className="af-class-image-20"
                />
              </div>
              <div className="af-class-express-contentt1">
                <div className="af-class-express-contentt1-title">
                  <div className="af-class-text-block-41">
                    BVAT’s IAO: initial Art offering
                  </div>
                  <div className="af-class-text-block-42">
                    BVAT announces the Initial Coin Distribution (ICD) of
                    100,000,000 (one hundred million) $BRAIN to the community
                  </div>
                </div>
                <div className="af-class-express-contentt1-main">
                  <div className="af-class-text-block-13">eth</div>
                  <div>
                    <div className="af-class-text-block-18">100,000,000</div>
                    <div className="af-class-text-block-14">$Brain</div>
                  </div>
                </div>
                <a
                  href="/brain"
                  className="af-class-express-contentt-explore-btn af-class-margin-130 w-button"
                >
                  EXPLORE
                </a>
                <div className="af-class-express-contentt2">
                  <div className="af-class-text-block-41">
                    Participation in the #TimesSquareHeist
                  </div>
                  <div className="af-class-text-block-42">
                    BVAT announces the Initial Coin Distribution (ICD) of
                    100,000,000 (one hundred million) $BRAIN to the community
                  </div>
                  <div className="af-class-express-contentt2-btn">
                    <a
                      href="/timesquare"
                      className="af-class-express-contentt-explore-btn w-inline-block"
                    >
                      EXPLORE
                    </a>
                  </div>
                </div>
                <div className="af-class-express-content6">
                  <img
                    src="images/Vector-4.png"
                    loading="lazy"
                    alt
                    className="af-class-express-content3-arrow"
                  />
                  <div className="af-class-express-content-title">
                    <img src="images/Rectangle-337.png" loading="lazy" alt />
                    <div className="af-class-text-block-41">
                      the HackingNFTs
                    </div>
                    <a
                      href="/nft"
                      className="af-class-express-contentt-explore-btn w-inline-block"
                    >
                      EXPLORE
                    </a>
                  </div>
                </div>
                <div className="af-class-express-content4">
                  <div className="af-class-express-content4-main">
                    <img
                      src="images/map-route.png"
                      loading="lazy"
                      alt
                      className="af-class-express-content4-arrow"
                    />
                    <div className="af-class-express-content4-main-content">
                      <div>
                        <div className="af-class-text-block-41">
                          “H” series PFPs
                        </div>
                        <div className="af-class-text-block-42">
                          BVAT announces the Initial Coin Distribution (ICD) of
                          100,000,000 (one hundred million) $BRAIN to the
                          community
                        </div>
                        <div className="af-class-express-contentt2-btn">
                          <a
                            href="#"
                            className="af-class-express-contentt-explore-btn af-class-margin-130 w-button"
                          >
                            EXPLORE
                          </a>
                        </div>
                      </div>
                      <div>
                        <div className="af-class-text-block-41">
                          “M” series NFTs
                        </div>
                        <div className="af-class-text-block-42">
                          BVAT announces the Initial Coin Distribution (ICD) of
                          100,000,000 (one hundred million) $BRAIN to the
                          community
                        </div>
                        <div className="af-class-express-contentt2-btn">
                          <a
                            href="#"
                            className="af-class-express-contentt-explore-btn w-button"
                          >
                            EXPLORE
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="af-class-express-content5">
                    <img
                      src="images/Line-1.png"
                      loading="lazy"
                      alt
                      className="af-class-image-37"
                    />
                    <img
                      src="images/Rectangle-341.png"
                      loading="lazy"
                      alt
                      className="af-class-image-22"
                    />
                  </div>
                </div>
                <div className="af-class-express-content6">
                  <div className="af-class-express-content-title">
                    <div className="af-class-express-content3-main-text">
                      pandora’s Box
                    </div>
                    <div className="af-class-text-block-17">
                      // Pandora’s Box <br />
                      small cap
                    </div>
                  </div>
                  <div className="af-class-express-content6-main">
                    <a
                      href="/pandora"
                      className="af-class-express-content6-btn w-inline-block"
                    >
                      <div className="af-class-express-content6-btn">
                        explore
                      </div>
                    </a>
                    <img
                      src="images/Rectangle-339.png"
                      loading="lazy"
                      sizes="(max-width: 767px) 100vw, (max-width: 991px) 77vw, 59vw"
                      srcSet="images/Rectangle-339-p-500.png 500w, images/Rectangle-339-p-800.png 800w, images/Rectangle-339-p-1080.png 1080w, images/Rectangle-339.png 1160w"
                      alt
                      className="af-class-express-content6-image"
                    />
                  </div>
                  <img
                    src="images/Line-2.png"
                    loading="lazy"
                    width={18}
                    alt
                    className="af-class-image-38"
                  />
                </div>
              </div>
            </div>
            <div className="af-class-footer af-class-wf-section">
              <div className="af-class-headerwrapper af-class-footer">
                <img
                  src="images/LogoBlack.svg"
                  loading="lazy"
                  alt
                  className="af-class-image"
                />
                <div className="af-class-footer-info">
                  <div className="af-class-footer-info-left">
                    <a href="#" className="af-class-footer-info-left-link">
                      Privacy policy
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Terms of use
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Cookies preference
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      White paper
                    </a>
                  </div>
                  <div className="af-class-footer-info-right">
                    <div className="af-class-footer-info-right-t">
                      Copyright © 2022 BVAT GROUP, &nbsp;All Rights Reserved
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default ExpressView;

/* eslint-enable */
