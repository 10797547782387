/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6280b0d0a95084768ba63eed"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class NftView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/NftController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = NftView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6286f2515435b838bdd0dd67";
    htmlEl.dataset["wfSite"] = "6280b0d0a95084768ba63eed";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      NftView.Controller !== NftView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/bvat-9916f6.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div className="af-class-body">
            <div
              data-w-id="c7e0fbbd-bfdd-ec38-fbbb-01e7445b9521"
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease-in"
              data-easing2="ease-out"
              role="banner"
              className="af-class-navigation-white w-nav"
            >
              <div className="af-class-container af-class-nav">
                <a href="/" className="af-class-mobile-logo w-nav-brand">
                  <img src="images/Logo-Black.png" loading="lazy" alt />
                </a>
                <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                  <a href="/" className="af-class-main-logo w-nav-brand">
                    <img
                      src="images/Logo-Black.png"
                      loading="lazy"
                      alt
                      className="af-class-image-12"
                    />
                  </a>
                  <div className="af-class-nav-links">
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/about"
                        className="af-class-header-link-block-1 w-inline-block"
                      >
                        <div className="af-class-header-link">Team</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/express"
                        className="af-class-header-link-block-2 w-inline-block"
                      >
                        <div className="af-class-header-link">express</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/philosophy"
                        className="af-class-header-link-block-3 w-inline-block"
                      >
                        <div className="af-class-header-link">philosphy</div>
                      </a>
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b9529-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/discord.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952a-445b9521"
                        alt
                        className="af-class-header-social-img"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952b-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/gitbook_logo_icon.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952c-445b9521"
                        alt
                        className="af-class-header-social-image-white"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952d-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/twitter.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952e-445b9521"
                        alt
                        className="af-class-header-social-img-white"
                      />
                    </div>
                  </div>
                </nav>
                <div className="af-class-menu-button w-nav-button">
                  <div className="w-icon-nav-menu" />
                </div>
              </div>
            </div>
            <div className="af-class-wf-section">
              <div className="af-class-div-block-56">
                <div
                  data-w-id="ed2844b3-be19-6ed7-a2b4-de157423cc0e"
                  style={{ opacity: 0 }}
                  className="af-class-nft-content-1"
                >
                  <div className="af-class-text-block-11">NFTs</div>
                  <div className="af-class-text-block-39">
                    carefully curated Ground-breaking chains of Fine-Art-based
                    NFTs only at BVAT
                  </div>
                </div>
              </div>
              <div className="af-class-div-block-55">
                <div
                  data-w-id="ed2844b3-be19-6ed7-a2b4-de157423cc14"
                  style={{ opacity: 0 }}
                  className="af-class-div-block-58"
                >
                  <div className="af-class-nft-content-2-half">
                    <div className="af-class-text-block-36">
                      Your Manifesto to the wrold
                    </div>
                    <div>
                      In resonance with the spirit of Web 3.0, we will undertake
                      decentralized communication as the axiom of our very first
                      presense. We will collect each token holder’s manifesto
                      and publish them as NFTs.
                      <br />‍<br />
                      <strong>
                        This very unique and intimate NFT collection will be
                        presented on the LED screens in the Times Square, NYC,
                        aka. #TimesSquareHeist.
                      </strong>
                    </div>
                  </div>
                  <div className="af-class-nft-content-2-half af-class-nft-content-2-half-right" />
                </div>
              </div>
              <div className="af-class-div-block-4">
                <div
                  data-w-id="ed2844b3-be19-6ed7-a2b4-de157423cc1c"
                  style={{ opacity: 0 }}
                  className="af-class-text-block-38"
                >
                  Collaborative &nbsp;Nature
                </div>
              </div>
              <div className="af-class-div-block-57">
                <div className="af-class-nft-content-5-box">
                  <div className="af-class-nft-content-5-box-t">
                    ”M“ for Masters
                  </div>
                  <div className="af-class-nft-content-5-box-p">
                    Many would consider with the ascendancy of social media
                    platforms is marking a tipping point in the history of fine
                    art, in which emerging artists are to be leveraged to
                    extensive exposures and acknowledgments through
                    self-publicizing; seemingly we have come to a phase when the
                    highness of fine art would, after all, submit itself to an
                    accessible utility. The innate exclusivity of art, parlaying
                    its prestige practice, nevertheless confers unceasing
                    centralized principles: obligating embedded privileges of
                    wealth and connections to accumulate a worthwhile amount of
                    recognition to surpass financial investment.
                  </div>
                </div>
                <div className="af-class-nft-content-5-box">
                  <div className="af-class-nft-content-5-box-t">
                    “H” for the Heist
                  </div>
                  <div className="af-class-nft-content-5-box-p">
                    The “H” series is the first release of the upcoming
                    many-to-come BVAT NFTs chains. Each successful applicant of
                    BVAT IAO will be able to claim their “H” NFT for free. Yes,
                    for free, it’s not a typo. In fact, you will be the creator
                    and the 1st collector of your “H” NFTs.
                  </div>
                </div>
                <img
                  src="images/NFT-content-4-r.svg"
                  loading="lazy"
                  alt
                  className="af-class-image-29"
                />
              </div>
              <div className="af-class-div-block-59">
                <div
                  data-w-id="ed2844b3-be19-6ed7-a2b4-de157423cc2b"
                  style={{ opacity: 0 }}
                  className="af-class-nft-content-6-box"
                >
                  <div className="af-class-text-block-36">Many to come</div>
                  <div className="af-class-text-block-37">
                    At BVAT and RAC, we believe in the power of collaborative
                    creative practices and the support of a community. The
                    community will stay as our top values and motifs, because
                    the community will empower the many-to-come mind-blowing
                    projects.
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-footer af-class-wf-section">
              <div className="af-class-headerwrapper af-class-footer">
                <img
                  src="images/LogoBlack.svg"
                  loading="lazy"
                  alt
                  className="af-class-image"
                />
                <div className="af-class-footer-info">
                  <div className="af-class-footer-info-left">
                    <a href="#" className="af-class-footer-info-left-link">
                      Privacy policy
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Terms of use
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Cookies preference
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      White paper
                    </a>
                  </div>
                  <div className="af-class-footer-info-right">
                    <div className="af-class-footer-info-right-t">
                      Copyright © 2022 BVAT GROUP, &nbsp;All Rights Reserved
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default NftView;

/* eslint-enable */
