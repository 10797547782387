/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6280b0d0a95084768ba63eed"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class AboutUsView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/AboutUsController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = AboutUsView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "6280b0d0a95084baa9a63eee";
    htmlEl.dataset["wfSite"] = "6280b0d0a95084768ba63eed";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      AboutUsView.Controller !== AboutUsView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <span className="af-view">
          <div>
            <div
              data-w-id="c7e0fbbd-bfdd-ec38-fbbb-01e7445b9521"
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease-in"
              data-easing2="ease-out"
              role="banner"
              className="af-class-navigation-white w-nav"
            >
              <div className="af-class-container af-class-nav">
                <a href="/index" className="af-class-mobile-logo w-nav-brand">
                  <img src="images/Logo-Black.png" loading="lazy" alt />
                </a>
                <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                  <a href="/index" className="af-class-main-logo w-nav-brand">
                    <img
                      src="images/Logo-Black.png"
                      loading="lazy"
                      alt
                      className="af-class-image-12"
                    />
                  </a>
                  <div className="af-class-nav-links">
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/about"
                        aria-current="page"
                        className="af-class-header-link-block-1 w-inline-block w--current"
                      >
                        <div className="af-class-header-link">Team</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/express"
                        className="af-class-header-link-block-2 w-inline-block"
                      >
                        <div className="af-class-header-link">express</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/philosophy"
                        className="af-class-header-link-block-3 w-inline-block"
                      >
                        <div className="af-class-header-link">philosphy</div>
                      </a>
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b9529-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/discord.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952a-445b9521"
                        alt
                        className="af-class-header-social-img"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952b-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/gitbook_logo_icon.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952c-445b9521"
                        alt
                        className="af-class-header-social-image-white"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952d-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/twitter.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952e-445b9521"
                        alt
                        className="af-class-header-social-img-white"
                      />
                    </div>
                  </div>
                </nav>
                <div className="af-class-menu-button w-nav-button">
                  <div className="w-icon-nav-menu" />
                </div>
              </div>
            </div>
            <div id="about-us" className="af-class-wf-section">
              <div className="af-class-headerwrapper af-class-about-banner">
                <div
                  data-w-id="0f3c696b-b3e2-945d-d6cb-8c98122457a7"
                  style={{ opacity: 0 }}
                  className="af-class-about-us-banner"
                >
                  <h1 className="af-class-about-us-heading">ABOUT US</h1>
                  <div className="af-class-about-us-banner-title">
                    <img
                      src="images/about_us_banner_vector.svg"
                      loading="lazy"
                      alt
                      className="af-class-image-11"
                    />
                  </div>
                  <div className="af-class-about-us-banner-t">
                    The Famous Brain in a vat thought experiment
                  </div>
                </div>
              </div>
              <div className="af-class-headerwrapper af-class-about-us-content-1">
                <div
                  data-w-id="2c150a57-cb83-c4b6-68a1-448cce8b2b92"
                  className="af-class-about-us-content-1-box"
                  onMouseEnter={() => {
                    const infoHover = document.querySelectorAll(
                      ".af-view .af-class-about-us-info-hover"
                    )[0];
                    infoHover.style.transform =
                      "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)";
                  }}
                  onMouseLeave={() => {
                    const infoHover = document.querySelectorAll(
                      ".af-view .af-class-about-us-info-hover"
                    )[0];
                    infoHover.style.transform =
                      "translate3d(0, 355px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)";
                  }}
                >
                  <img
                    src="images/about_us_box_left.svg"
                    loading="lazy"
                    alt
                    className="af-class-about-us-content-img"
                  />
                  <div className="af-class-about-us-info-hover af-class-about-us-info-hover-left">
                    <div className="af-class-about-us-info-hover-tile">
                      <h1 className="af-class-heading">BVAT Group</h1>
                      <div>
                        The RAC Ecosystem consists of Artist Incubation and Art
                        Collection Pipeline. The Incubation will take place in
                        the BVAT Metaverse infrastructure, in the form of
                        Artists in Residence and Meta Panel Discussion.
                      </div>
                    </div>
                    <div className="af-class-about-us-button">
                      <a
                        href="/about-bvat-group"
                        className="af-class-link-block-3 w-inline-block"
                      >
                        <div className="af-class-text-block">DISCOVER</div>
                      </a>
                    </div>
                  </div>
                </div>
                <img
                  src="images/about_us_bf.svg"
                  loading="lazy"
                  alt
                  className="af-class-butterfly"
                />
                <div
                  data-w-id="b55f657e-f6ac-6135-d2d5-98fb57a05881"
                  className="af-class-about-us-content-1-box af-class-box-right"
                  onMouseEnter={() => {
                    const infoHover = document.querySelectorAll(
                      ".af-view .af-class-about-us-info-hover"
                    )[1];
                    infoHover.style.transform =
                      "translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)";
                  }}
                  onMouseLeave={() => {
                    const infoHover = document.querySelectorAll(
                      ".af-view .af-class-about-us-info-hover"
                    )[1];
                    infoHover.style.transform =
                      "translate3d(0, 355px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)";
                  }}
                >
                  <img
                    src="images/about_us_box_right.svg"
                    loading="lazy"
                    alt
                    className="af-class-about-us-content-img"
                  />
                  <div
                    data-w-id="bfc83bac-e4fd-9be2-f427-e667710ab714"
                    className="af-class-about-us-info-hover"
                  >
                    <div className="af-class-about-us-info-hover-tile">
                      <h1 className="af-class-heading">RAC</h1>
                      <div>
                        The RAC Ecosystem consists of Artist Incubation and Art
                        Collection Pipeline. The Incubation will take place in
                        the BVAT Metaverse infrastructure, in the form of
                        Artists in Residence and Meta Panel Discussion.
                      </div>
                    </div>
                    <div className="af-class-about-us-button">
                      <a
                        href="/about-rac"
                        className="af-class-link-block-4 w-inline-block"
                      >
                        <div className="af-class-text-block">DISCOVER</div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-footer af-class-wf-section">
              <div className="af-class-headerwrapper af-class-footer">
                <img
                  src="images/LogoBlack.svg"
                  loading="lazy"
                  alt
                  className="af-class-image"
                />
                <div className="af-class-footer-info">
                  <div className="af-class-footer-info-left">
                    <a href="#" className="af-class-footer-info-left-link">
                      Privacy policy
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Terms of use
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Cookies preference
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      White paper
                    </a>
                  </div>
                  <div className="af-class-footer-info-right">
                    <div className="af-class-footer-info-right-t">
                      Copyright © 2022 BVAT GROUP, &nbsp;All Rights Reserved
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default AboutUsView;

/* eslint-enable */
