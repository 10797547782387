/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6280b0d0a95084768ba63eed"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class PandoraView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/PandoraController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = PandoraView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "62858d32a91ff2955fce497b";
    htmlEl.dataset["wfSite"] = "6280b0d0a95084768ba63eed";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      PandoraView.Controller !== PandoraView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/bvat-9916f6.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div className="af-class-body-3">
            <div
              data-w-id="c7e0fbbd-bfdd-ec38-fbbb-01e7445b9521"
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease-in"
              data-easing2="ease-out"
              role="banner"
              className="af-class-navigation-white w-nav"
            >
              <div className="af-class-container af-class-nav">
                <a href="/" className="af-class-mobile-logo w-nav-brand">
                  <img src="images/Logo-Black.png" loading="lazy" alt />
                </a>
                <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                  <a href="/" className="af-class-main-logo w-nav-brand">
                    <img
                      src="images/Logo-Black.png"
                      loading="lazy"
                      alt
                      className="af-class-image-12"
                    />
                  </a>
                  <div className="af-class-nav-links">
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/about"
                        className="af-class-header-link-block-1 w-inline-block"
                      >
                        <div className="af-class-header-link">Team</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/express"
                        className="af-class-header-link-block-2 w-inline-block"
                      >
                        <div className="af-class-header-link">express</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/philosophy"
                        className="af-class-header-link-block-3 w-inline-block"
                      >
                        <div className="af-class-header-link">philosphy</div>
                      </a>
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b9529-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/discord.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952a-445b9521"
                        alt
                        className="af-class-header-social-img"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952b-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/gitbook_logo_icon.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952c-445b9521"
                        alt
                        className="af-class-header-social-image-white"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952d-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/twitter.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952e-445b9521"
                        alt
                        className="af-class-header-social-img-white"
                      />
                    </div>
                  </div>
                </nav>
                <div className="af-class-menu-button w-nav-button">
                  <div className="w-icon-nav-menu" />
                </div>
              </div>
            </div>
            <div className="af-class-div-block-38">
              <img
                src="images/1_BVAT_Homepage2_2x.png"
                loading="lazy"
                height
                alt
                className="af-class-image-41"
              />
              <div
                data-w-id="d1788802-dcb2-e885-969d-497c9caad37e"
                style={{ opacity: 0 }}
                className="af-class-div-block-39"
              >
                <div className="af-class-text-title af-class-erc721-banner-text">
                  PANDORA‘S BOX
                </div>
                <div className="af-class-text-paragraph af-class-erc721-banner-text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit
                  massa convallis phasellus tincidunt viverra mattis purus est.
                  Aliquam semper ullamcorper in volutpat proin lacus, bibendum
                  aenean dictum. Amet ac vitae rutrum odio. Aliquam viverra
                  commodo vitae dapibus.
                </div>
              </div>
            </div>
            <div className="af-class-div-block-41">
              <div className="af-class-div-block-43" />
              <div
                data-w-id="e5d60597-a031-4cb8-458a-f337d7f52681"
                style={{ opacity: 0 }}
                className="af-class-div-block-45"
              >
                <div className="af-class-text-block-30">
                  Pandora Box Design Concept：
                </div>
                <div className="af-class-text-block-31">
                  In Greek mythology, Pandora’s box refers to an artifact
                  carrying a part of humanity that was given to Pandora with
                  specific instructions not to open it. However, unable to
                  control her impulses and curiosity, Pandora opened the jar,
                  which could have been a blessing if kept locked, turned into
                  something evil that plagued us until now.
                  <br />
                  Similarly, a portion of every contribution, every NFT in the
                  form of tokens, is kept in BVAT’s Pandora’s Pool.
                </div>
              </div>
              <div className="af-class-div-block-42" />
            </div>
            <div className="af-class-erc721-info-1">
              <div
                data-w-id="d7ce503a-173a-05d3-6f6a-4fa1bbfdc28b"
                style={{ opacity: 0 }}
                className="af-class-text-title af-class-text-center"
              >
                ERC 721 &nbsp;BVAT EXPRESS
              </div>
              <div
                data-w-id="375fd136-1b16-6020-af08-8d0012c3e13d"
                style={{ opacity: 0 }}
                className="af-class-text-paragraph"
              >
                the whitelist NFT of BVAT
              </div>
            </div>
            <div className="af-class-erc721-info-2">
              <div
                data-w-id="180c9e30-3404-f680-efbb-bbd865280994"
                style={{ opacity: 0 }}
                className="af-class-div-block-46"
              >
                <div className="af-class-text-title af-class-erc721-info-2-t">
                  a total supply of <br />
                  5,500 BVAT Express NFTs
                </div>
                <div className="af-class-text-paragraph2">
                  BVAT express is the whitelist NFT which allows holder to mint
                  all NFTs and participates in any event in the BVAT ecosystem.
                </div>
                <div className="af-class-text-paragraph2">
                  The #TimesSquareHeist, “M” series NFTs, “H” series PFPs, W
                  series, Meta-X Entry
                </div>
              </div>
            </div>
            <div className="af-class-div-block-47">
              <img src="images/Vector-3.png" loading="lazy" alt />
              <div
                data-w-id="2b4853ae-6bad-c74c-e6db-86f7a0d86992"
                style={{ opacity: 0 }}
                className="af-class-div-block-49"
              >
                <div className="af-class-text-block-35">CHARGING</div>
                <div className="af-class-text-paragraph3">
                  Charge your $Brain tokens, in Pandora’s Pool to get your BVAT
                  express NFT. We have employed a continuous function for BVAT
                  Express NFT charging system, in contrast to the stepping
                  function most NFT projects employs:
                  <br />
                  Where n is the number of BVAT Express NFT previously mint:
                </div>
                <div className="af-class-text-paragraph-right">
                  14,000 $BRAIN * 1.00004^n
                </div>
              </div>
            </div>
            <div className="af-class-div-block-47">
              <div className="af-class-div-block-65">
                <img
                  src="images/burn.png"
                  loading="lazy"
                  alt
                  className="af-class-burning-image"
                />
              </div>
              <div
                data-w-id="b3dc8435-4597-d90d-e182-9c77342ad271"
                style={{ opacity: 0 }}
                className="af-class-div-block-49 af-class-text-mirror"
              >
                <div className="af-class-text-block-35">BURNING</div>
                <div className="af-class-text-paragraph3">
                  Once you own a BVAT express NFT from pandora's pool or OS, you
                  may choose to burn your BVAT EXPRESS NFT and get your $BRAIN
                  tokens back.
                  <br />
                  <br />
                  *It will take 10% transaction fee to burn it.
                </div>
              </div>
            </div>
            <div className="af-class-div-block-47">
              <div className="af-class-div-block-50">
                <div className="af-class-text-paragraph">$brain</div>
                <img src="images/Vector-4_1.png" loading="lazy" alt />
                <div className="af-class-text-paragraph">
                  ERC 721 BVAT EXPRESS
                </div>
              </div>
              <div
                data-w-id="9d7e518a-f524-59ff-58b8-40f5346107f2"
                style={{ opacity: 0 }}
                className="af-class-div-block-49"
              >
                <div className="af-class-text-block-35">“H“series NFT</div>
                <div className="af-class-text-paragraph3">
                  All H3 NFT holders have the right to burn their NFT in
                  exchange for $Brain that has been released according to the
                  timeline…
                  <br />
                  Initial “H” series NFT 🔥 = 45% Total token
                </div>
              </div>
            </div>
            <div className="af-class-div-block-47-copy">
              <div
                data-w-id="752fcb4d-f6ca-5757-1b0d-7a7d58d3c9f1"
                style={{ opacity: 0 }}
                className="af-class-div-block-49"
              >
                <div className="af-class-text-block-40">
                  45% of all generated tokens will be placed in Pandora’s POOL
                  to be temporarily locked and released following a specific
                  timeline.
                </div>
                <div className="af-class-text-paragraph3">
                  *35% of tokens in the pool will be released in the 1st month
                  of project launch
                </div>
              </div>
              <img
                src="images/Group-631752.png"
                loading="lazy"
                alt
                className="af-class-image-32"
              />
            </div>
            <div className="af-class-div-block-52">
              <div
                data-w-id="d0b7e9b4-8225-6031-99f8-67c965d1723b"
                style={{ opacity: 0 }}
                className="af-class-div-block-51"
              >
                <div className="af-class-text-paragraph1">GROWTH</div>
                <div className="af-class-text-paragraph4">
                  Every token-holder will be able to see the limitless box grow
                  as the BVAT ecosystem expands, and everyone is free to burn
                  their NFTs in exchange for tokens as they wish, however, the
                  concept remains the same. If the box is opened, then poof,
                  what could have been a magnificent and beautiful thing if left
                  as is disappears forever. As long as the box isn’t opened, the
                  possibilities are endless…
                </div>
              </div>
            </div>
            <div className="af-class-div-block-13" />
            <div className="af-class-footer af-class-wf-section">
              <div className="af-class-headerwrapper af-class-footer">
                <img
                  src="images/LogoBlack.svg"
                  loading="lazy"
                  alt
                  className="af-class-image"
                />
                <div className="af-class-footer-info">
                  <div className="af-class-footer-info-left">
                    <a href="#" className="af-class-footer-info-left-link">
                      Privacy policy
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Terms of use
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Cookies preference
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      White paper
                    </a>
                  </div>
                  <div className="af-class-footer-info-right">
                    <div className="af-class-footer-info-right-t">
                      Copyright © 2022 BVAT GROUP, &nbsp;All Rights Reserved
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default PandoraView;

/* eslint-enable */
