/* eslint-disable */

import React from "react";
import { createScope, map, transformProxies } from "./helpers";

const scripts = [
  {
    loading: fetch(
      "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=6280b0d0a95084768ba63eed"
    ).then((body) => body.text()),
    isAsync: false,
  },
  {
    loading: fetch("js/webflow.js").then((body) => body.text()),
    isAsync: false,
  },
];

let Controller;

class AboutBvatGroupView extends React.Component {
  static get Controller() {
    if (Controller) return Controller;

    try {
      Controller = require("../controllers/AboutBvatGroupController");
      Controller = Controller.default || Controller;

      return Controller;
    } catch (e) {
      if (e.code == "MODULE_NOT_FOUND") {
        Controller = AboutBvatGroupView;

        return Controller;
      }

      throw e;
    }
  }

  componentDidMount() {
    const htmlEl = document.querySelector("html");
    htmlEl.dataset["wfPage"] = "62832becaef96991ecb886dc";
    htmlEl.dataset["wfSite"] = "6280b0d0a95084768ba63eed";

    scripts.concat(null).reduce((active, next) =>
      Promise.resolve(active).then((active) => {
        const loading = active.loading.then((script) => {
          new Function(`
          with (this) {
            eval(arguments[0])
          }
        `).call(window, script);

          return next;
        });

        return active.isAsync ? next : loading;
      })
    );
  }

  render() {
    const proxies =
      AboutBvatGroupView.Controller !== AboutBvatGroupView
        ? transformProxies(this.props.children)
        : {};

    return (
      <span>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          @import url(/css/normalize.css);
          @import url(/css/webflow.css);
          @import url(/css/bvat-9916f6.webflow.css);
        `,
          }}
        />
        <span className="af-view">
          <div className="af-class-body-6">
            <div
              data-w-id="c7e0fbbd-bfdd-ec38-fbbb-01e7445b9521"
              data-animation="default"
              data-collapse="medium"
              data-duration={400}
              data-easing="ease-in"
              data-easing2="ease-out"
              role="banner"
              className="af-class-navigation-white w-nav"
            >
              <div className="af-class-container af-class-nav">
                <a href="/" className="af-class-mobile-logo w-nav-brand">
                  <img src="images/Logo-Black.png" loading="lazy" alt />
                </a>
                <nav role="navigation" className="af-class-nav-menu w-nav-menu">
                  <a href="/" className="af-class-main-logo w-nav-brand">
                    <img
                      src="images/Logo-Black.png"
                      loading="lazy"
                      alt
                      className="af-class-image-12"
                    />
                  </a>
                  <div className="af-class-nav-links">
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/about"
                        className="af-class-header-link-block-1 w-inline-block"
                      >
                        <div className="af-class-header-link">Team</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/express"
                        className="af-class-header-link-block-2 w-inline-block"
                      >
                        <div className="af-class-header-link">express</div>
                      </a>
                    </div>
                    <div className="af-class-header-logo-div af-class-header-tab">
                      <a
                        href="/philosophy"
                        className="af-class-header-link-block-3 w-inline-block"
                      >
                        <div className="af-class-header-link">philosphy</div>
                      </a>
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b9529-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/discord.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952a-445b9521"
                        alt
                        className="af-class-header-social-img"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952b-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/gitbook_logo_icon.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952c-445b9521"
                        alt
                        className="af-class-header-social-image-white"
                      />
                    </div>
                    <div
                      id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952d-445b9521"
                      className="af-class-header-logo-div"
                    >
                      <img
                        src="images/twitter.svg"
                        loading="lazy"
                        id="w-node-c7e0fbbd-bfdd-ec38-fbbb-01e7445b952e-445b9521"
                        alt
                        className="af-class-header-social-img-white"
                      />
                    </div>
                  </div>
                </nav>
                <div className="af-class-menu-button w-nav-button">
                  <div className="w-icon-nav-menu" />
                </div>
              </div>
            </div>
            <div className="af-class-bannerwrapper">
              <div
                data-w-id="8a3bfb5b-513c-45c7-b572-bb99d3e2aa4d"
                style={{ opacity: 0 }}
                className="af-class-banner"
              >
                <img
                  src="images/about_us_box_left.svg"
                  loading="lazy"
                  alt
                  className="af-class-brainimg"
                />
                <div className="af-class-titlewrapper">
                  <h1 className="af-class-title">bvat group</h1>
                  <div className="af-class-subtitle">
                    a web 3.0 based group of art advocaters
                  </div>
                </div>
              </div>
            </div>
            <div className="af-class-artscenewrapper">
              <div
                data-w-id="66df2c43-823d-c69c-63cb-e59a35269eb4"
                style={{ opacity: 0 }}
                className="af-class-content"
              >
                <div className="af-class-artscene">
                  <h1 className="af-class-heading-3">web 3.0 art scene</h1>
                  <div>
                    The development of economy has always been closely tied to
                    the metamorphosis of art and cultural movements. We
                    currently rely on centralized stream media and technology
                    institutions to exchange information, in which the agency of
                    the user is dependent on the centralized web infrastructures
                    through institutional censorships. <br />
                    <br />
                    BVAT offers an assurance of an assurance of transparency and
                    liquidity to the NFT community through a decentralized
                    blockchain-based platform, connecting the realms of Finance,
                    Technology and Fine Art
                  </div>
                </div>
                <img
                  src="images/VI-13-1.png"
                  loading="lazy"
                  alt
                  className="af-class-artsceneimg"
                />
              </div>
            </div>
            <div className="af-class-content2wrapper">
              <div
                data-w-id="7c088725-db42-22d8-e61e-c5ff1b9efb98"
                style={{ opacity: 0 }}
                className="af-class-contenttext"
              >
                <div className="af-class-content2">Transparency</div>
                <div className="af-class-content2">Authenticity</div>
                <div className="af-class-content2">Creativity</div>
              </div>
            </div>
            <div className="af-class-content3wrapper">
              <div className="af-class-content3">
                <img src="images/Layer-1-copy-Image.png" loading="lazy" alt />
              </div>
            </div>
            <div className="af-class-divider" />
            <div className="af-class-footer af-class-wf-section">
              <div className="af-class-headerwrapper af-class-footer">
                <img
                  src="images/LogoBlack.svg"
                  loading="lazy"
                  alt
                  className="af-class-image"
                />
                <div className="af-class-footer-info">
                  <div className="af-class-footer-info-left">
                    <a href="#" className="af-class-footer-info-left-link">
                      Privacy policy
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Terms of use
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      Cookies preference
                    </a>
                    <a href="#" className="af-class-footer-info-left-link">
                      White paper
                    </a>
                  </div>
                  <div className="af-class-footer-info-right">
                    <div className="af-class-footer-info-right-t">
                      Copyright © 2022 BVAT GROUP, &nbsp;All Rights Reserved
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* [if lte IE 9]><![endif] */}
          </div>
        </span>
      </span>
    );
  }
}

export default AboutBvatGroupView;

/* eslint-enable */
